import React from 'react';
import logo from "./logo.png"

export const PageText = "In progress...";

export const App: React.FC = () => {
	const logoSize = 400;
	const pageTextDivStyle: React.CSSProperties = {
		textAlign: "center", 
		fontSize: "25px"
	};
	const logoImageDivStyle: React.CSSProperties = {
		margin: "auto",
		maxWidth: `${logoSize}px`
	};
	const logoImageStyle: React.CSSProperties = {
		height: "100%",
		width: "100%",
		maxHeight: `${logoSize}px`,
		maxWidth: `${logoSize}px`
	};

	return (
		<div>
			<div style={pageTextDivStyle}>
				<p>{PageText}</p>
			</div>
			<br />
			<div style={logoImageDivStyle}>
				<img 
					style={logoImageStyle}
					src={logo}
					alt="Ghassane Adnani Logo" 
					aria-label="Ghassane Adnani Logo"
				/>
			</div>
		</div>
	);
};
